const MuiButton = {
  defaultProps: {
    disableElevation: true
  },
  styleOverrides: {
    root: {
      borderRadius: 12,
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '20px',
      textTransform: 'capitalize'
    },
    sizeSmall: {
    },
    sizeMedium: {
    },
    sizeLarge: {
        height: 60
    },
    containedPrimary: ({ ownerState, theme }) => ({
      '&.Mui-disabled': {
        color: '#ffffff'
      }
    }),
    containedSecondary: ({ ownerState, theme }) => ({
      color: '#ffffff'
    })
  }
};

export default MuiButton;
