import { createTheme } from '@mui/material/styles';
import components from './components/components';

export const lightGray = '#8293AC1A';
export const gray = '#465063';

export default createTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'Montserrat',
      'sans-serif',
    ].join(','),
  },
  palette: {
    type: 'light',
    background: {
      default: '#8293AC',
      green: '#EBF6EF',
    },
    primary: {
      light: '#02082D',
      main: '#02082D',
      dark: '#02082D',
    },
    secondary: {
      light: '#00C891',
      main: '#00C891',
      dark: '#00C891',
      contrastText: '#5C5D73',
    },
    error: {
      light: '#FC4848',
      main: '#FC4848',
      dark: '#FC4848',
    },
    action: {},
    text: {
      primary: '#02082D',
      secondary: '#465063',
      light: '#C5C5C5',
      // disabled: 'rgba(22, 23, 52, 0.25)'
    },
  },
  components,
});
