import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import { PAGE_QUESTIONS, PAGES_MAP } from '../utils/constants';
import { useForm, FormProvider } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ContinueButton from './components/ContinueButton';
import { clickToScroll } from '../utils/helpers';
import trackAmplitude from '../services/analytics';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

const MainLayout = () => {
  const { t } = useTranslation();
  const { pageName = 'Page1' } = useParams();
  const methods = useForm();
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);

  const formPageValues = methods.watch(pageName);

  const handleClickContinue = () => {};

  const onSubmit = (data) => {
    setDisable(true);
    const eventName = PAGE_QUESTIONS[pageName]?.eventName;

    // track user answer to amplitude
    if (!isEmpty(data[pageName] || formPageValues)) {
      eventName &&
        trackAmplitude(`${eventName}_answer`, {
          value: data[pageName] || formPageValues,
        });
    }

    clickToScroll('root > .MuiContainer-root');
    navigate(PAGES_MAP[pageName]);
  };

  const NextButton = PAGE_QUESTIONS[pageName]?.ButtonType || ContinueButton;

  useEffect(() => {
    clickToScroll('root > .MuiContainer-root');
    setDisable(false);
  }, [pageName]);

  return (
    <FormProvider {...methods}>
      <Container
        maxWidth="xs"
        disableGutters
        sx={{
          height: '100vh',
          px: 20 / 8,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box flex={1} pb={20}>
          <Outlet
            context={{
              navigate,
              pageName,
              handleClickContinue,
            }}
          />
        </Box>

        <Box
          sx={{
            position: 'fixed',
            left: 0,
            right: 0,
            bottom: 0,
            background: '#fff',
            boxShadow: '0px 0px 15px 10px #fff',
          }}
        >
          <Container
            maxWidth="xs"
            disableGutters
            sx={{
              px: 20 / 8,
            }}
          >
            <Stack spacing={2} pb={20 / 8}>
              {['Page4', 'Page7', 'Page16', 'Page20', 'Page27'].includes(
                pageName
              ) && (
                <Typography
                  sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '22px',
                    letterSpacing: '-0.02em',
                    textAlign: 'center',
                  }}
                >
                  {/*{pageName === 'Page4' && <>Already with SMMHUB? <strong>Sign in</strong></>}*/}
                  {/*{pageName === 'Page7' && <>Already with SMMHUB? <strong>Sign in</strong></>}*/}
                  {pageName === 'Page16' &&
                    `*${t('common.according_to_survey')}`}
                  {pageName === 'Page20' &&
                    `*${t('common.according_to_survey')}`}
                  {pageName === 'Page27' &&
                    `*${t('common.according_to_survey')}`}
                </Typography>
              )}

              <NextButton
                text={t(PAGE_QUESTIONS[pageName]?.btnText)}
                disabled={
                  (!(formPageValues?.length > 0) &&
                    PAGE_QUESTIONS.hasOwnProperty(pageName) &&
                    PAGE_QUESTIONS[pageName].hasOwnProperty('questions')) ||
                  (!methods.formState.isValid && pageName === 'Page28') ||
                  disable
                }
                onClick={methods.handleSubmit(onSubmit)}
              />
            </Stack>
            {['Page31'].includes(pageName) && (
              <Typography
                pb={2}
                sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '10px',
                  fontWeight: 400,
                  lineHeight: '16px',
                  letterSpacing: '-0.02em',
                  color: '#8293ac',
                }}
              >
                {t('dynamicPage.final_results_may_vary')}
              </Typography>
            )}
          </Container>
        </Box>
      </Container>
    </FormProvider>
  );
};

export default MainLayout;
