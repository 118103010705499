import React from 'react';
import Box from '@mui/material/Box';


const TextGradientWrapper = ({
  children,
  gradientColor = 'linear-gradient(95.49deg, #FF006B 10.61%, #8F00FF 60.31%, #01A4FF 95.66%)',
  sx
}) => {
  return (
    <Box
      component='span'
      sx={{
        backgroundImage: gradientColor,
        backgroundClip: 'text',
        textFillColor: 'transparent',
        boxDecorationBreak: 'clone',
        ...sx
      }}
    >
      {children}
    </Box>
  );
};

export default TextGradientWrapper;