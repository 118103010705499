import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { clickToScroll } from '../utils/helpers';

const NotFound = () => {
  const navigate = useNavigate();
  useEffect(() => {
    clickToScroll('root > .MuiContainer-root');
  }, []);

  return (
    <Container
      maxWidth='xs'
      disableGutters
      sx={{
        height: '100vh',
        px: 20 / 8,
        display: 'flex',
        alignItem:' center',
        justifyContent: 'center',
        flexDirection: 'column'
      }}
    >
      <Button
        variant='outlined'
        size='large'
        onClick={() =>  navigate('/')}
      >
        Back to home
      </Button>
    </Container>
  );
};

export default NotFound;