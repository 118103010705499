import { outlinedInputClasses } from '@mui/material';
import { createTheme } from '@mui/material/styles';

export const lightGray = '#8293AC1A';
export const gray = '#465063';

export default createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Gilroy',
    },
    gradient: {
      fontWeight: 800,
      backgroundImage:
        'linear-gradient(109.15deg, #FF006B 9.64%, #8F00FF 73.1%, #01A4FF 124.91%)',
      backgroundClip: 'text',
      textFillColor: 'transparent',
      boxDecorationBreak: 'clone',
    },
    h1: {
      fontSize: '24px',
      fontWeight: 800,
      textAlign: 'center',
    },
    h2: {
      fontSize: '16px',
      fontWeight: 800,
      textAlign: 'center',
    },
    body1: {
      fontWeight: 500,
      fontSize: '14px',
    },
    fontFamily: [
      'Gilroy',
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'Montserrat',
      'sans-serif',
    ].join(','),
  },
  palette: {
    secondary: { main: '#02082D' },
    success: {
      main: '#00C891',
    },
    error: {
      main: '#FC4848',
    },
    common: {
      borderColor: 'red',
    },
    text: {
      primary: '#02082D',
      secondary: '#808396',
    },
    grey: {
      main: '#8293AC1F',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          transition: 'border-color 0.3s',
          borderColor: '#8293AC33',
        },
        root: {
          backgroundColor: '#8293AC0D',
          borderRadius: 12,
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'black',
            borderWidth: 1,
          },
        },
        input: {
          '&::placeholder': {
            color: (theme) => theme.palette.text.secondary,
          },
          fontWeight: 500,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          padding: 16,
          borderRadius: 12,
          fontSize: '15px',
          fontWeight: 700,
          textTransform: 'unset',
          background:
            ownerState.variant === 'contained' &&
            ownerState.color !== 'secondary'
              ? 'linear-gradient(140deg, #FF006B 9.64%, #8F00FF 73.1%, #01A4FF 124.91%)'
              : undefined,
          '&.Mui-disabled': {
            background: '#8293AC1F',
            color: 'white',
          },
        }),
        text: ({ theme }) => ({
          color: theme.palette.text.secondary,
          fontWeight: 500,
        }),
        outlined: ({ theme }) => ({
          ...theme.typography.gradient,
          border: 'none !important',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: '1px solid transparent',
            borderRadius: '12px',
            backgroundImage: `linear-gradient(white, white), ${theme.typography.gradient.backgroundImage}`,
            backgroundOrigin: 'border-box',
            backgroundClip: 'padding-box, border-box, text',
            textFillColor: 'transparent',
            zIndex: -1,
          },
        }),
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          padding: 0,
          borderRadius: 0,
        },
      },
    },
    MuiContainer: {
      styleOverrides: { root: { padding: '0 20px', paddingBottom: '40px' } },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiSvgIcon-root': {
            fontSize: 22,
          },
          '&.Mui-checked': {
            color: '#00C891',
          },
        }),
      },
    },
    MuiPaper: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          padding: 20,
          borderRadius: 12,
          border: '1px solid',
          borderColor: '#80839633',
          boxShadow: 'unset',
        },
      },
      variants: [
        {
          props: { variant: 'error' },
          style: ({ theme }) => ({
            background: '#FC48481A',
            borderColor: theme.palette.error.main,
            color: theme.palette.error.main,
          }),
        },
      ],
    },
  },
});
