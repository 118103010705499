import { alpha } from '@mui/material/styles';

const MuiOutlinedInput = {
  defaultProps: {
    disableElevation: true,

  },
  styleOverrides: {
    root: {
      borderRadius: 12,
    },
    sizeMedium: {

    },
    input: {
      height: 20,
      '&::placeholder': {
        color: theme => alpha(theme.palette.text.secondary, 0.23)
      }
    }
  }
};


export default MuiOutlinedInput;